<template>
    <form class="authentication-form create-account-confirm">
      <dx-form>
        <dx-item>
            <template #default>
                <div>
                    <div :class="classStatus">
                        <div class="picto">
                            <img :src="`/images/${status}.svg`" />
                        </div>  
                        <div class="message">
                            <div class="text">{{ message }}</div>
                        </div>                
                    </div>
                </div>
            </template>
        </dx-item>
        <dx-item
            item-type="button"
            :button-options="{
                width: '100%',
                type: 'normal',
                stylingMode: 'outlined',
                template: 'createTemplate',
                onClick: createConfirm
            }"
        />
        <template #createTemplate>
          <div>
            <span class="dx-button-text">
                <span class="arrow-to-right">{{i18n.t('authentication-basic.common.signin')}}</span>
            </span>
          </div>
        </template>
      </dx-form>
    </form> 
</template>

<script>
import module_i18n from '../i18n' 

import DxForm, {
  DxItem,
} from 'devextreme-vue/form';

export default {
    components: {
        DxForm,
        DxItem,
    },
    data() {
        return {
            module_i18n,
            status: 'pending',
            message: 'En cours de vérification'
        }
    },
    async beforeMount() {
        const result = await this.$store.dispatch('authentication-basic/CONFIRMACCOUNT', this.token)
        this.status = result.success ? 'success' : 'error'
        this.message = result.message
    },
    methods: {
        createConfirm() {
            this.$router.push({ name: "login" }).catch()
        }
    },
    computed: {
        i18n() {
            return this.$i18n ? this.$i18n : this.module_i18n
        },
        classStatus() {
            return {
                "form-step-status": true,
                "create-account-status": true,
                "create-account-success": this.confirmed,
                "create-account-error": this.error,
            }
        },
        token() {
            return this.$router.currentRoute.params && this.$router.currentRoute.params.confirmationToken ? this.$router.currentRoute.params.confirmationToken : null
        },
        loading() {
            return this.$store.getters['authentication-basic/GET_LOADING']
        },
    }
}
</script>

<style lang="scss">

.create-account-confirm {

    padding: 10px;

    .create-account-status {
        text-align: center;
        

        .picto, .text {
            padding: 20px;

        }

        .picto img {
            width: 100px;
        }

        .text {
            width: 100%;
        }
    }
}
</style>