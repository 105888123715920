<template>
    <form class="authentication-form reset-password-form" @submit.prevent="onSubmit">
        
        <!-- <component v-if="hasBeforeForm" :is="slots.beforeForm"></component> -->

        <dx-form 
            @initialized="onFormInitialized" 
            :form-data="formData" 
            :show-colon-after-label="showCol"
            :label-mode="labelMode" 
            :label-location="labelLocation"
        >         
            <dx-item
                data-field="resetToken"
                :editor-options="{ visible: false }"
            >
            </dx-item>   
            <dx-item
                data-field="newPassword"
                :is-required="true"
                :label="{ text: i18n.t('authentication-basic.reset-password.new-password') }"
                :editor-options="{ mode: 'password' }"
            >
                <dx-required-rule :message="i18n.t('authentication-basic.common.password_is_required')" />
                <dx-pattern-rule
                    :pattern="passwordPattern"
                    :message="i18n.t('authentication-basic.common.password-pattern')"
                />
            </dx-item>
            <dx-item
                data-field="confirmPassword"
                :is-required="true"
                :label="{ text: i18n.t('authentication-basic.reset-password.confirm_new_password') }"
                :editor-options="{ mode: 'password' }"
            >
                <dx-required-rule :message="i18n.t('authentication-basic.common.password_is_required')" />
                <dx-custom-rule
                :message="i18n.t('authentication-basic.create-account.password_not_match')"
                :validation-callback="confirmPassword"
                />
            </dx-item>
            <dx-button-item>
                <dx-button-options
                    width="100%"
                    type="normal"
                    template="resetPassword"
                    :use-submit-behavior="true"
                >
                </dx-button-options>
            </dx-button-item>
            <dx-item>
                <template #default>
                <div class="login-link">
                    {{i18n.t('authentication-basic.common.return_to')}} <router-link class="arrow-to-right" to="/login">{{i18n.t('authentication-basic.common.signin')}}</router-link>
                </div>
                </template>
            </dx-item>
            <template #resetPassword>
                <div>
                <span class="dx-button-text">
                    <dx-loadIndicator v-if="loading" width="24px" height="24px" :visible="true" />
                    <span>{{ i18n.t('authentication-basic.create-account.submit') }}</span>
                </span>
                </div>
            </template>
        </dx-form>
    </form>
</template>

<script>
import module_i18n from '../i18n' 
import notify from 'devextreme/ui/notify';
import DxLoadIndicator from 'devextreme-vue/load-indicator';
import DxForm, {
  DxItem,
  DxButtonItem,
  DxButtonOptions,
  DxCustomRule,
  DxPatternRule,
  DxRequiredRule,
} from 'devextreme-vue/form';

export default {
    components: {
        DxForm,
        DxItem,
        DxButtonItem,
        DxButtonOptions,
        DxCustomRule,
        DxPatternRule,
        DxRequiredRule,
        DxLoadIndicator
    },
    // props: {
    //     slots: {
    //         type: Object,
    //         default: () => ({})
    //     }
    // },
    computed: {
        // hasBeforeForm() {
        //     return !!this.slots.beforeForm
        // },
        i18n() {
            return this.$i18n ? this.$i18n : this.module_i18n
        },
        labelLocation() {
            return this.$router.currentRoute.meta && this.$router.currentRoute.meta.labelLocation ? this.$router.currentRoute.meta.labelLocation : 'left'
        },
        labelMode() {
            return this.$router.currentRoute.meta && this.$router.currentRoute.meta.labelMode ? this.$router.currentRoute.meta.labelMode : 'outside'
        },
        showCol() {
            return this.labelMode === 'outside'
        } 
    },
    data() {
        return {
            module_i18n,
            form: null,
            formData: {
                resetToken: this.$router.currentRoute.params && this.$router.currentRoute.params.resetToken ? this.$router.currentRoute.params.resetToken : null
            },
            passwordPattern: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/,
            loading: false
        }
    },
    methods: {
        onFormInitialized(e) {
            this.form = e.component
        },
        onSubmit() {
            const form = this.form
            if (form.validate().isValid) {
                this.loading = true;

                this.$store.dispatch(`authentication-basic/RESETPASSWORD`,this.formData)
                .then((result) => {
                    // console.log("RESULT:",result)
                    if (!result.success) {
                        this.loading = false;
                        notify(result.message, "error", 2000);
                    } else {
                        // this.$router.push({name: "login-form"}).catch(() => {});
                        this.$router.push({ name: "login" }).catch(() => {});
                    }
                })
                .catch((err) => {
                    this.loading = false;
                    notify(err.message, "error", 2000);
                })
            }
        },
        confirmPassword(e) {
            return e.value === this.formData.newPassword;
        }
    }
}
</script>