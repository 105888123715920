<template>
  <form class="authentication-form create-account-form" @submit.prevent="onSubmit">
    <component 
      v-if="hasCreateAccountForm"
      @initialized="onFormInitialized"
      :is="slots.createAccountForm"
      :type="type"
      :form-data.sync="formData"
      :loading="loading"
      :confirm-password="confirmPassword"
      :show-colon-after-label="showCol"
      :label-mode="labelMode" 
      :label-location="labelLocation"
      :onFormValueChanged="onFormValueChanged"
    />

    <!-- <dx-form v-else-if="formItems" @initialized="onFormInitialized" :items="formItems" :form-data="formData" :disabled="loading" /> -->

    <dx-form v-else 
      @initialized="onFormInitialized" 
      :form-data="formData" 
      :disabled="loading" 
      :show-colon-after-label="showCol"
      :label-mode="labelMode" 
      :label-location="labelLocation"
      @fieldDataChanged="onFormValueChanged"
    >
      <dx-item
        data-field="login"
        :is-required="true"
        :label="{ text: i18n.t('authentication-basic.common.login') }"
        :editor-options="{ mode: 'email' }"
      >
          <dx-required-rule :message="i18n.t('authentication-basic.common.email_is_required')" />
          <dx-email-rule :message="i18n.t('authentication-basic.common.email_is_invalid')" />
      </dx-item>
      <dx-item
        v-if="isCreateMode"
        data-field="password"
        :is-required="true"
        :label="{ text: i18n.t('authentication-basic.common.password') }"
        :editor-options="{ mode: 'password' }"
      >
        <dx-required-rule :message="i18n.t('authentication-basic.common.password_is_required')" />
        <dx-pattern-rule
          :pattern="passwordPattern"
          :message="i18n.t('authentication-basic.common.password-pattern')"
        />
      </dx-item>
      <dx-item
        v-if="isCreateMode"
        data-field="confirmPassword"
        :is-required="true"
        :label="{ text: i18n.t('authentication-basic.create-account.confirm_password') }"
        :editor-options="{ mode: 'password' }"
      >
        <dx-required-rule :message="i18n.t('authentication-basic.common.password_is_required')" />
        <dx-custom-rule
          :message="i18n.t('authentication-basic.create-account.password_not_match')"
          :validation-callback="confirmPassword"
        />
      </dx-item>
      <dx-item v-if="isCreateMode">
        <template #default>
          <div class='policy-info'>
            {{i18n.t('authentication-basic.common.creating_accout_conditions')}} <router-link to="#">{{i18n.t('authentication-basic.common.terms_of_service')}}</router-link> {{i18n.t('authentication-basic.common.and')}} <router-link to="#">{{i18n.t('authentication-basic.common.privacy_policy')}}</router-link>
          </div>
        </template>
      </dx-item>
      <dx-button-item>
        <dx-button-options
          width="100%"
          type="normal"
          template="createAccount"
          :use-submit-behavior="true"
        >
        </dx-button-options>
      </dx-button-item>
      <dx-button-item v-if="!isCreateMode" :button-options="logoutButtonOptions">
      </dx-button-item>
      <dx-item v-if="isCreateMode">
        <template #default>
          <div class="login-link">
            {{i18n.t('authentication-basic.create-account.have_account')}}? <router-link class="arrow-to-right" to="/login">{{i18n.t('authentication-basic.common.signin')}}</router-link>
            <!-- <div>TEST : <router-link to="/create-account-confirm?createState=1">CONFIRM</router-link> / <router-link to="/create-account-confirm?createState=2">ERROR</router-link></div> -->
          </div>
        </template>
      </dx-item>
      <template #createAccount>
        <div>
          <span class="dx-button-text">
              <dx-loadIndicator v-if="loading" width="24px" height="24px" :visible="true" />
              <span v-if="!loading">{{ i18n.t('authentication-basic.create-account.submit') }}</span>
          </span>
        </div>
      </template>
      <template #changePassword>
        <div>
          <span class="dx-button-text">
              <dx-loadIndicator v-if="loading" width="24px" height="24px" :visible="true" />
              <span v-if="!loading">{{ i18n.t('authentication-basic.common.change-password') }}</span>
          </span>
        </div>
      </template>
      <template #logOut>
        <div>
          <span class="dx-button-text">
              <dx-loadIndicator v-if="loading" width="24px" height="24px" :visible="true" />
              <span v-if="!loading">{{ i18n.t('authentication-basic.logout.button') }}</span>
          </span>
        </div>
      </template>
    </dx-form>
  </form>
</template>

<script>
import module_i18n from '../i18n' 
import DxForm, {
  DxItem,
  DxLabel,
  DxButtonItem,
  DxButtonOptions,
  DxCustomRule,
  DxRequiredRule,
  DxEmailRule,
  DxPatternRule,
} from 'devextreme-vue/form';
import DxLoadIndicator from 'devextreme-vue/load-indicator';
import notify from 'devextreme/ui/notify';
// import auth from "../auth";

import { formTypes, createStates } from '../types'

export default {
  components: {
    DxForm,
    DxItem,
    DxLabel,
    DxButtonItem,
    DxButtonOptions,
    DxRequiredRule,
    DxCustomRule,
    DxPatternRule,
    DxEmailRule,
    DxLoadIndicator
  },
  props: {
    type: {
      type: Number,
      default: () => (formTypes.CREATE)
    },
    slots: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
        module_i18n,
        form: null,
        formData: {},
        passwordPattern: "^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\\W)(?!.* ).{8,16}$",
        logoutButtonOptions: { 
          onClick: this.logout,
          width: "100%",
          type: "normal",
          template: "logOut",
          useSubmitBehavior: false,
        }
    }
  },
  async beforeMount() {
    if (!this.isCreateMode) await this.$store.dispatch('authentication-basic/FETCHPROFILE')
    this.formData = this.createAccountFormData
  },
  computed: {
    i18n() {
        return this.$i18n ? this.$i18n : this.module_i18n
    },
    hasCreateAccountForm() {
      return !!this.slots.createAccountForm 
    },
    labelLocation() {
      return this.$router.currentRoute.meta && this.$router.currentRoute.meta.labelLocation ? this.$router.currentRoute.meta.labelLocation : 'left'
    },
    labelMode() {
      return this.$router.currentRoute.meta && this.$router.currentRoute.meta.labelMode ? this.$router.currentRoute.meta.labelMode : 'outside'
    },
    showCol() {
      return this.labelMode === 'outside'
    },
    isCreateMode() {
      return this.type === formTypes.CREATE
    },
    formAction() {
      return this.isCreateMode ? `authentication-basic/CREATEACCOUNT` : `authentication-basic/UPDATEACCOUNT`
    },
    createAccountFormData() {
      return {...this.$store.getters['authentication-basic/GET_USER']}
    },
    loading() {
      return this.$store.getters['authentication-basic/GET_LOADING']
    }
  },
  methods: {
    onFormInitialized(e) {
      this.form = e.component
    },
    onFormValueChanged() {
      const formData = {...this.formData}
      if (formData.login && formData.login !== '') formData.login = formData.login.toLowerCase()
      this.$store.commit('authentication-basic/SET_USER',{...formData})
      // this.$emit('update:form-data',{...this.forwardFormData})
    },
    logout() {
      // console.log("logout")
      this.$store.dispatch('authentication-basic/LOGOUT')
    },
    onSubmit() {
      const form = this.form
      if (form.validate().isValid) {
        if(!this.isCreateMode) this.$store.dispatch(`addresses/CREATE_OR_UPDATE_ADDRESS`)
        this.$store.dispatch(`${this.formAction}`)
          .then((result) => {
              // console.log("RESULT:",result)
              if (!result.success) {
                notify(result.message, "error", 2000);
              } else {
                // this.$router.push({name: "login-form"}).catch(() => {});
                if (this.type === formTypes.CREATE) this.$router.push({ name: "create-account-confirm", params: {createState: createStates.CONFIRM}}).catch(() => {});
                else notify(result.message, "success", 2000);
              }
          })
          .catch((err) => {
              notify(err.message, "error", 2000);
          })
      }
    },
    confirmPassword(e) {
      return e.value === this.createAccountFormData.password;
    }
  }
}
</script>
