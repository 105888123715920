<template>
    <form class="authentication-form create-account-confirm">
      <dx-form>
        <dx-item>
            <template #default>
                <div>
                    <div :class="classStatus">
                        <div class="picto">
                            <img :src="`/images/${status}.svg`" />
                        </div>  
                        <div class="message">
                            <div class="text">{{i18n.tc(`authentication-basic.create-account.notification_${status}`,5)}}</div>
                        </div>                
                    </div>
                    <!-- <div class="login-link">
                        {{i18n.t('authentication-basic.common.return_to')}} <router-link to="/login">{{i18n.t('authentication-basic.common.signin')}}</router-link>
                    </div> -->
                </div>
            </template>
        </dx-item>
        <dx-item
            item-type="button"
            :button-options="{
                width: '100%',
                type: 'normal',
                stylingMode: 'outlined',
                template: 'createTemplate',
                onClick: createConfirm
            }"
        />
        <template #createTemplate>
          <div>
            <span class="dx-button-text">
                <span class="arrow-to-right">{{i18n.t('authentication-basic.common.signin')}}</span>
            </span>
          </div>
        </template>
      </dx-form>
    </form> 
    <!-- <div class="authentication-form create-account-confirm">
        <div :class="classStatus">
            <div class="picto">
                <img :src="`/images/${status}.svg`" />
            </div>  
            <div class="message">
                <div class="text">{{i18n.tc(`authentication-basic.create-account.notification_${status}`,5)}}</div>
            </div>                
        </div>

        <div class="login-link">
            {{i18n.t('authentication-basic.common.return_to')}} <router-link to="/login">{{i18n.t('authentication-basic.common.signin')}}</router-link>
        </div>
    </div> -->
</template>

<script>
import module_i18n from '../i18n' 
import { createStates } from '../types'

import DxForm, {
  DxItem,
} from 'devextreme-vue/form';

export default {
    components: {
        DxForm,
        DxItem,
    },
    data() {
        return {
            module_i18n,
        }
    },
    methods: {
        createConfirm() {
            // console.log("createConfirm")
            this.$router.push({ name: "login" }).catch()
        }
    },
    computed: {
        i18n() {
            return this.$i18n ? this.$i18n : this.module_i18n
        },
        error() {
            return this.createState === createStates.ERROR
        },
        confirmed() {
            return this.createState === createStates.CONFIRM
        },
        createState() {
            return parseInt(this.$route.query.createState || this.$route.params.createState) || 2
        },
        classStatus() {
            return {
                "form-step-status": true,
                "create-account-status": true,
                "create-account-success": this.confirmed,
                "create-account-error": this.error,
            }
        },
        status() {
            return this.error ? "error" : "success"
        }
    }
}
</script>

<style lang="scss">

.create-account-confirm {

    padding: 10px;

    .create-account-status {
        text-align: center;
        

        .picto, .text {
            padding: 20px;

        }

        .picto img {
            width: 100px;
        }

        .text {
            width: 100%;
        }
    }
}
</style>