<template>
    <form class="authentication-form forgot-password-confirm">
      <dx-form>
          <dx-item>
            <template #default>
                <div>
                    <div :class="classStatus">
                        <div class="picto">
                            <img :src="`/images/${status}.svg`" />
                        </div>  
                        <div class="message">
                            <div class="text">{{i18n.tc(`authentication-basic.forgot-password.notification_${status}`,5)}}</div>
                            <div class="email">{{email}}</div>
                        </div>                
                    </div>
                    <!-- <div class="login-link">
                        {{i18n.t('authentication-basic.common.return_to')}} <router-link to="/login">{{i18n.t('authentication-basic.common.signin')}}</router-link>
                    </div> -->
                </div>
            </template>
        </dx-item>
        <dx-item
            item-type="button"
            :button-options="{
                width: '100%',
                type: 'normal',
                stylingMode: 'outlined',
                template: 'createTemplate',
                onClick: forgotConfirm
            }"
        />
        <template #createTemplate>
          <div>
            <span class="dx-button-text">
                <span class="arrow-to-right">{{i18n.t('authentication-basic.common.signin')}}</span>
            </span>
          </div>
        </template>
      </dx-form>
    </form>
</template>

<script>
import module_i18n from '../i18n' 
import { forgotStates } from './forgot-password-form'

import DxForm, {
  DxItem,
} from 'devextreme-vue/form';

export default {
    components: {
        DxForm,
        DxItem
    },
    props: ['forgotState', 'eMail'],
    data() {
        return {
            module_i18n
        }
    },
    methods: {
        forgotConfirm() {
            this.$router.push({ name: "login" }).catch()
        }
    },
    computed: {
        i18n() {
            return this.$i18n ? this.$i18n : this.module_i18n
        },
        error() {
            return this.forgotStateValue === forgotStates.ERROR
        },
        confirmed() {
            return this.forgotStateValue === forgotStates.CONFIRM
        },
        email() {
            return this.eMail || this.$route.query.email || this.$route.params.email || this.i18n.t('authentication-basic.common.unknow_email')
        },
        forgotStateValue() {
            return parseInt(this.forgotState || this.$route.query.forgotState || this.$route.params.forgotState) || 2
        },
        classStatus() {
            return {
                "form-step-status": true,
                "forgot-password-status": true,
                "forgot-password-success": this.confirmed,
                "forgot-password-error": this.error,
            }
        },
        status() {
            return this.error ? "error" : "success"
        }
    }
}
</script>

<style lang="scss">

.forgot-password-confirm {

    .forgot-password-status {
        display: flex;
        justify-content: center;
        align-items: center;

        padding: 80px;

        .picto img {
            width: 60px;
        }

        .text {
            width: 100%;
        }
    }
}
</style>