<template>
  <form class="authentication-form login-form" @submit.prevent="onSubmit">
    <dx-form :form-data="formData" :disabled="loading" :show-colon-after-label="showCol" :label-mode="labelMode" :label-location="labelLocation">
      <dx-item
        data-field="email"
        editor-type="dxTextBox"
        :label="{ text: i18n.t('authentication-basic.common.login') }"
        :editor-options="{ stylingMode: 'filled', mode: 'email' }"
      >
        <dx-required-rule :message="i18n.t('authentication-basic.common.email_is_required')" />
        <dx-email-rule :message="i18n.t('authentication-basic.common.email_is_invalid')" />
      </dx-item>
      <dx-item
        data-field='password'
        editor-type='dxTextBox'
        :label="{ text: i18n.t('authentication-basic.common.password') }"
        :editor-options="{ stylingMode: 'filled', mode: 'password' }"
      >
        <dx-required-rule :message="i18n.t('authentication-basic.common.password_is_required')" />
      </dx-item>

      <dx-item
        v-if="showRemember"
        data-field="rememberMe"
        editor-type="dxCheckBox"
        :editor-options="{ text: i18n.t('authentication-basic.common.rememberMe'), elementAttr: { class: 'form-text' } }"
      >
      </dx-item>

      <dx-item>
        <template #default>
          <div class="link">
            <router-link to="/forgot-password">{{i18n.t('authentication-basic.login-form.forgot_password')}} ?</router-link>
          </div>
        </template>
      </dx-item>

      <dx-button-item>
        <dx-button-options          
          width="100%"
          type="normal"
          template="signInTemplate"
          :use-submit-behavior="true"
        >
        </dx-button-options>
      </dx-button-item>
  
      <dx-item>
        <template #default>
          <div class="link link-signin">
            {{i18n.t('authentication-basic.login-form.no_account')}} ? <router-link class="arrow-to-right" to="/create-account">{{i18n.t('authentication-basic.login-form.i_signup')}}</router-link>
          </div>
        </template>
      </dx-item>
      <template #signInTemplate>
        <div>
          <span class="dx-button-text">
            <dx-load-indicator v-if="loading" width="24px" height="24px" :visible="true" />
            <span v-if="!loading">{{i18n.t('authentication-basic.common.signin')}}</span>
          </span>
        </div>
      </template>
    </dx-form>
  </form>
</template>

<script>
import module_i18n from '../i18n' 
import DxLoadIndicator from "devextreme-vue/load-indicator";
import DxForm, {
  DxItem,
  DxEmailRule,
  DxRequiredRule,
  DxButtonItem,
  DxButtonOptions
} from "devextreme-vue/form";
import notify from 'devextreme/ui/notify';

// import auth from "../auth";

export default {
  props: {
    showRemember: {
      type: Boolean,
      default: () => false
    },
    slots: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      module_i18n,
      formData: {}
    };
  },
  computed: {
    i18n() {
        return this.$i18n ? this.$i18n : this.module_i18n
    },
    loading() {
      return this.$store.state['authentication-basic'].loading
    },
    hasBeforeForm() {
      return !!this.slots.beforeForm
    },
    hasAfterForm() {
      return !!this.slots.afterForm
    },
    labelLocation() {
      return this.$router.currentRoute.meta && this.$router.currentRoute.meta.labelLocation ? this.$router.currentRoute.meta.labelLocation : 'left'
    },
    labelMode() {
      return this.$router.currentRoute.meta && this.$router.currentRoute.meta.labelMode ? this.$router.currentRoute.meta.labelMode : 'outside'
    },
    showCol() {
      return this.labelMode === 'outside'
    } 
  },
  methods: {
    onCreateAccountClick() {
      this.$router.push("/create-account").catch(() => {});
    },
    onSubmit: async function() {
      const { email, password } = this.formData;

      this.$store.dispatch('authentication-basic/LOGIN',{login: email.toLowerCase(), password})
      .then((result) => {
          // console.log("RESULT:",result)
          if (!result.success) {
            notify(result.message, "error", 2000);
          } else {
            this.$router.push(this.$route.query.redirect || "/home").catch(() => {});
          }
      })
      .catch((err) => {
          notify(err.message, "error", 2000);
      })

      // const result = await auth.logIn(email, password);
      // if (!result.isOk) {
      //   this.loading = false;
      //   notify(result.message, "error", 2000);
      // } else {
      //   this.$router.push(this.$route.query.redirect || "/home").catch(() => {});
      // }
    }
  },
  components: {
    DxLoadIndicator,
    DxForm,
    DxEmailRule,
    DxRequiredRule,
    DxItem,
    DxButtonItem,
    DxButtonOptions
  }
};
</script>

