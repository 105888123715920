<template>
    <form class="authentication-form forgot-password-form" @submit.prevent="onSubmit">
      <dx-form :form-data="formData" :disabled="loading" :show-colon-after-label="showCol" :label-mode="labelMode" :label-location="labelLocation">
        <dx-item
          data-field="email"
          editor-type="dxTextBox"
          css-class="mailForgotInput"
          :label="{ text: i18n.t('authentication-basic.common.login') }"
        :editor-options="{ stylingMode: 'filled', mode: 'email' }"
        >
          <dx-required-rule :message="i18n.t('authentication-basic.common.email_is_required')" />
          <dx-email-rule :message="i18n.t('authentication-basic.common.email_is_invalid')" />
        </dx-item>
        <dx-button-item>
          <dx-button-options
            :element-attr="{ class: 'submit-button' }"
            width="100%"
            type="normal"
            template="forgotTemplate"
            :use-submit-behavior="true"
          >
          </dx-button-options>
        </dx-button-item>
        <dx-item>
          <template #default>
            <div class="login-link">
              {{i18n.t('authentication-basic.common.return_to')}} <router-link class="arrow-to-right" to="/login">{{i18n.t('authentication-basic.common.signin')}}</router-link>
              <!-- <div>TEST : <router-link to="/forgot-password-confirm?forgotState=1">CONFIRM</router-link> / <router-link to="/forgot-password-confirm?forgotState=2">ERROR</router-link></div> -->
            </div>
          </template>
        </dx-item>
        <template #forgotTemplate>
          <div>
            <span class="dx-button-text">
                <dx-load-indicator v-if="loading" width="24px" height="24px" :visible="true" />
                <span v-if="!loading">{{i18n.t('authentication-basic.forgot-password.forgot_my_password')}}</span>
            </span>
          </div>
        </template>
      </dx-form>
    </form>
</template>

<script>
import module_i18n from '../i18n' 
import DxForm, {
  DxItem,
  DxButtonItem,
  DxButtonOptions,
  DxRequiredRule,
  DxEmailRule
} from 'devextreme-vue/form';
import DxLoadIndicator from 'devextreme-vue/load-indicator';
import notify from 'devextreme/ui/notify';

export const forgotStates = {
    forgot: 0,
    CONFIRM: 1,
    ERROR: 2
}

export default {
  components: {
    DxForm,
    DxItem,
    DxButtonItem,
    DxButtonOptions,
    DxRequiredRule,
    DxEmailRule,
    DxLoadIndicator
  },
  data() {
    return {
        module_i18n,
        formData: {},
        loading: false
    }
  },
  computed: {
    i18n() {
        return this.$i18n ? this.$i18n : this.module_i18n
    },
    labelLocation() {
      return this.$router.currentRoute.meta && this.$router.currentRoute.meta.labelLocation ? this.$router.currentRoute.meta.labelLocation : 'left'
    },
    labelMode() {
      return this.$router.currentRoute.meta && this.$router.currentRoute.meta.labelMode ? this.$router.currentRoute.meta.labelMode : 'outside'
    },
    showCol() {
      return this.labelMode === 'outside'
    } 
  },
  methods: {
    onSubmit: async function() {
      const { email } = this.formData;
      this.loading = true;

      this.$store.dispatch('authentication-basic/FORGOTPASSWORD', {email})
      .then(result => {
          this.loading = false;
          if (result.success) {
            // this.$router.push({ name: "login"}).catch(() => {});
            this.$router.push({ name: "forgot-password-confirm", params: {email: email.toLowerCase() ,forgotState: forgotStates.CONFIRM}}).catch(() => {});
            notify(this.i18n.tc('authentication-basic.forgot-password.notification_success',5,{email: email.toLowerCase()}), "success", 2500);
          }
          else {
            notify(this.i18n.tc('authentication-basic.forgot-password.notification_error',5,{email: email.toLowerCase()}), "error", 2000);
          }
      })
      .catch(err => {
        this.loading = false;
        notify(err.message, "error", 2000);
      })
    }
  }
}
</script>

<style lang="scss" scoped>


  .mailForgotInput{
   .dx-texbox{
      max-width: 500px;
      margin: auto;
      margin-bottom: auto;
      margin-bottom: 30px;
   } 
  }



</style>

